import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css'
import { FormHelperText, Box, Typography, Button } from '@mui/material';
import AdminUserType from './adminUserVerify'

function LoginForm() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginMessage, setLoginMessage] = useState('');
    const uuid = localStorage.getItem('villageId')

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Email:', email);
        console.log('Password:', password);
        if (email === 'admin@admin.com' && password === 'admin') {
            alert('Login successful!')
            setLoginMessage('Login successful!');
            navigate(`/Verify-User-list?uuid=${uuid}`);
            setTimeout(() => {
                setLoginMessage('');
            }, 2000);

        } else {
            alert('Invalid email or password.')
            setLoginMessage('Invalid email or password.');
            setTimeout(() => {
                setLoginMessage('');
            }, 2000);
        }

    };
    const handleBack = () => {
        navigate(`/?uuid=${uuid}`);
    };

    return (
        <>
        {
            AdminUserType() ? (
                <Box>
                <Button variant="contained" onClick={handleBack} sx={{ position: 'absolute', top: 16, left: 16 }}>
                Back
            </Button>

                <form className="form_main" action="" onSubmit={handleSubmit}>
                <p className="heading">Login</p>
                <Box className="inputContainer">
                    <svg
                        viewBox="0 0 16 16"
                        fill="#2e2e2e"
                        height={16}
                        width={16}
                        xmlns="http://www.w3.org/2000/svg"
                        className="inputIcon"
                    >
                        <path d="M13.106 7.222c0-2.967-2.249-5.032-5.482-5.032-3.35 0-5.646 2.318-5.646 5.702 0 3.493 2.235 5.708 5.762 5.708.862 0 1.689-.123 2.304-.335v-.862c-.43.199-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.798 0-2.844 1.921-4.881 4.594-4.881 2.735 0 4.608 1.688 4.608 4.156 0 1.682-.554 2.769-1.416 2.769-.492 0-.772-.28-.772-.76V5.206H8.923v.834h-.11c-.266-.595-.881-.964-1.6-.964-1.4 0-2.378 1.162-2.378 2.823 0 1.737.957 2.906 2.379 2.906.8 0 1.415-.39 1.709-1.087h.11c.081.67.703 1.148 1.503 1.148 1.572 0 2.57-1.415 2.57-3.643zm-7.177.704c0-1.197.54-1.907 1.456-1.907.93 0 1.524.738 1.524 1.907S8.308 9.84 7.371 9.84c-.895 0-1.442-.725-1.442-1.914z" />
                    </svg>
                    <input
                        placeholder="Username"
                        id="username"
                        className="inputField"
                        type="text"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Box>
                <Box className="inputContainer">
                    <svg
                        viewBox="0 0 16 16"
                        fill="#2e2e2e"
                        height={16}
                        width={16}
                        xmlns="http://www.w3.org/2000/svg"
                        className="inputIcon"
                    >
                        <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
                    </svg>
                    <input
                        placeholder="Password"
                        id="password"
                        className="inputField"
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Box>
                <button id="button">Submit</button>
                {loginMessage && (
                    <FormHelperText error className={`alert ${loginMessage === 'Login successful!' ? 'alert-success' : 'alert-danger'} text-center`} role="alert">
                        {loginMessage}
                    </FormHelperText>
                )}

                <Box className="signupContainer">
                    <p>Don't have any account?</p>
                    <a href="#">Sign up</a>
                </Box>
            </form>
            </Box>
            ) : (<>
                <Button variant="contained" onClick={handleBack} sx={{ position: 'absolute', top: 16, left: 16 }}>
                Back
            </Button>
                <Typography variant='h2' color='error'>You are not authorized to access this page.</Typography>
           </> )}  
        </>

    );
}

export default LoginForm;
