import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from './App';
import VideoPlay from './PersonalInfoFrom.js';
import UserList from './userList'
import VerifyUsers from './verifyUsers.js'
import Admin from './admin.js'

const rootElement = document.getElementById("root");

// Use createRoot instead of ReactDOM.render
const root = ReactDOM.createRoot(rootElement);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="Register-user" element={<VideoPlay />} />
      <Route path="User-list" element={<UserList />} />
      <Route path="Verify-User-list" element={<VerifyUsers />} />
      <Route path="admin" element={<Admin />} />
    </Routes>
  </BrowserRouter>
);
