import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import AdminUserType from './adminUserVerify'

function DataTable() {
    const [formDataArray, setFormDataArray] = useState([]);
    const uuid = localStorage.getItem('villageId')

    const navigate = useNavigate();

    useEffect(() => {
        const savedData = localStorage.getItem('personalInfoArray');
        if (savedData) {
            setFormDataArray(JSON.parse(savedData));
        }
    }, []);

    const handleDelete = (id) => {
        const updatedArray = formDataArray.filter(item => item.id !== id);
        setFormDataArray(updatedArray);
        localStorage.setItem('personalInfoArray', JSON.stringify(updatedArray));
    };

    const haversineDistance = (lat1, lon1, lat2, lon2) => {
        const toRad = (value) => (value * Math.PI) / 180;
        const R = 6371000; 

        const dLat = toRad(lat2 - lat1);
        const dLon = toRad(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c;
    };

    const fixedLocation = { latitude: 18.6400508, longitude: 73.737538};
    const distanceThreshold = 200000; // in meters

    const handleBack = () => {
        navigate(`/?uuid=${uuid}`);
    };

    return (
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Button variant="contained" onClick={handleBack} sx={{ position: 'absolute', top: 16, left: 16 }}>
                Back
            </Button>

            {formDataArray.length > 0 ? (
                <>
                    <Typography variant='h3'>User List</Typography>
                    <TableContainer component={Paper} sx={{ width: '110%' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Full Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Phone Number</TableCell>
                                    <TableCell>Address</TableCell>
                                    <TableCell>Location</TableCell>
                                    {AdminUserType() && <TableCell>Actions</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formDataArray.map((row) => {
                                    const isWithinRange = row.latitude && row.longitude
                                        ? haversineDistance(
                                            fixedLocation.latitude,
                                            fixedLocation.longitude,
                                            row.latitude,
                                            row.longitude
                                        ) <= distanceThreshold
                                        : false;

                                    return (
                                        <TableRow key={row.id}>
                                            <TableCell>{row.id}</TableCell>
                                            <TableCell>{row.fullName}</TableCell>
                                            <TableCell>{row.email}</TableCell>
                                            <TableCell>{row.phoneNumber}</TableCell>
                                            <TableCell>{row.address}</TableCell>
                                            <TableCell>
                                                <Typography variant='h6' style={{ color: isWithinRange ? 'green' : 'red' }}>
                                                    {isWithinRange ? 'Within Village Area' : 'Out of Range'}
                                                </Typography>
                                            </TableCell>
                                            {AdminUserType() && (
                                                <TableCell padding='none'>
                                                    <Button
                                                        sx={{ height: '25px', ml: 1 }}
                                                        variant="contained"
                                                        color="error"
                                                        onClick={() => handleDelete(row.id)}
                                                    >
                                                        Delete
                                                    </Button>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            ) : (
                <Typography variant="h6" component="div">
                    No data found.
                </Typography>
            )}
        </Box>
    );
}

export default DataTable;
