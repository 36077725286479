import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

function DataTable() {
    const [formDataArray, setFormDataArray] = useState([]);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const userType = searchParams.get('uuid') ? searchParams.get('uuid') : '';

    const navigate = useNavigate();

    useEffect(() => {
        const savedData = localStorage.getItem('personalInfoArray');
        if (savedData) {
            setFormDataArray(JSON.parse(savedData));
        }
    }, []);

    const handleBack = () => {
        navigate(`/?uuid=${userType}`);
    };

    return (
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Button variant="contained" onClick={handleBack} sx={{ position: 'absolute', top: 16, left: 16 }}>
                Back
            </Button>

            {formDataArray.length > 0 ? (<>
                <Typography variant='h3'>User List</Typography>
                <TableContainer sx={{ width: '110%' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Full Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Phone Number</TableCell>
                                <TableCell>Address</TableCell>
                                <TableCell>Location</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {formDataArray.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>{row.fullName}</TableCell>
                                    <TableCell>{row.email}</TableCell>
                                    <TableCell>{row.phoneNumber}</TableCell>
                                    <TableCell>{row.address}</TableCell>
                                    <TableCell> latitude : {row.latitude}, longitude : {row.longitude}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer></>
            ) : (
                <Typography variant="h6" component="div">
                    No data found.
                </Typography>
            )}
        </Box>
    );
}

export default DataTable;
