import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import './app.css'; // Import your CSS file for styling
import { Modal, Box, Typography } from '@mui/material';
import welcomeData from './data/welcome.json';

function App() {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const villageId = searchParams.get('uuid') ? searchParams.get('uuid') : 'c7f3ff14-8b5b-4fce-b606-b1768e37c205';
  const [data, setData] = useState(null);

 

  useEffect(() => {
    // Modal Button
    const modal = document.getElementById("myModal");
    const btn = document.getElementById("button-modal");
    const span = document.getElementsByClassName("close-modal")[0];

    btn.onclick = function () {
      modal.style.display = "block";
    };

    span.onclick = function () {
      modal.style.display = "none";
    };

    window.onclick = function (event) {
      if (event.target === modal) {
        modal.style.display = "none";
      }
    };

    window.notifAlert = function () {
      alert("Maaf, fitur belum tersedia.");
    };

    let settings = {
      slide_interval: 5000, 
      transition_duration: 750, 
      autoplay: true,
    };

    let counter = 0;
    let slideInterval;
    const slideshow = document.querySelector("#swd-slideshow");

    // Create arrow elements
    const leftArrowElement = document.createElement('span');
    leftArrowElement.className = 'arrow-left arrow';
    leftArrowElement.innerHTML = '<i class="bi bi-arrow-left-circle"></i>';

    const rightArrowElement = document.createElement('span');
    rightArrowElement.className = 'arrow-right arrow';
    rightArrowElement.innerHTML = '<i class="bi bi-arrow-right-circle"></i>';

    // Append arrows to slideshow
    slideshow.appendChild(leftArrowElement);
    slideshow.appendChild(rightArrowElement);

    const slides = document.querySelectorAll("#swd-slideshow > div");
    const arrowLeft = document.querySelector("#swd-slideshow .arrow-left");
    const arrowRight = document.querySelector("#swd-slideshow .arrow-right");

    slides.forEach((slide) => {
      slide.style.transitionDuration = settings.transition_duration + "ms";
    });

    // Slideshow mechanics
    arrowRight.addEventListener("click", nextSlide);
    arrowLeft.addEventListener("click", prevSlide);

    startSlider();

    arrowLeft.addEventListener("mouseover", () => clearInterval(slideInterval));
    arrowRight.addEventListener("mouseover", () => clearInterval(slideInterval));

    arrowLeft.addEventListener("mouseleave", () => {
      if (settings.autoplay) {
        startAutoplay();
      }
    });

    arrowRight.addEventListener("mouseleave", () => {
      if (settings.autoplay) {
        startAutoplay();
      }
    });

    function reset() {
      counter = 0;
      showSlide(counter);
    }

    function nextSlide() {
      hideSlide(counter);

      if (++counter === slides.length) {
        counter = 0;
      }

      showSlide(counter);
    }

    function prevSlide() {
      hideSlide(counter);

      if (--counter === -1) {
        counter = slides.length - 1;
      }

      showSlide(counter);
    }

    function hideSlide(counter) {
      slides[counter].style.opacity = 0;
    }

    function showSlide(counter) {
      slides[counter].style.opacity = 1;
    }

    function startAutoplay() {
      slideInterval = setInterval(nextSlide, settings.slide_interval);
    }

    function startSlider() {
      reset();
      if (settings.autoplay) {
        startAutoplay();
      }
    }
  }, []);
  
  useEffect(() => {
      const villageData = welcomeData.find(item => item.uuid === villageId);
      setData(villageData);
      localStorage.setItem('villageId', villageId)
  }, [villageId]);

  console.log(data, welcomeData, villageId)
  return (
    <>
      <title>Desa Bringinbendo</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.0/font/bootstrap-icons.css"
      />
      <link
        href="https://www.cssscript.com/demo/image-swd-slideshow/css/swdslideshow.css"
        rel="stylesheet"
      />
      <Box className="top-bar">
        <a className="text-logo" href="https://neutronautomation.in/" target="_blank">
          <img
            className="logo-img"
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrxCPSbaJ3ozCz1tnHIT4J-XI0pwpCiTGURg&s"
          />{" "}
          VillageVision
        </a>
        <span className="icon-menu" id="button-modal">
          <i className="bi bi-list" />
        </span>
        <Box className="modal" id="myModal">
          <Box className="modal-content">
            <Box className="close-modal" />
            <h3>Menu</h3>
            <a className="link" href={`Register-user?uuid=${villageId}`} onclick="notifAlert()">
              Register User <i className="bi bi-diagram-3" />
            </a>
            <a className="link" href={`User-list?uuid=${villageId}`} onclick="notifAlert()">
             User List  <i className="bi bi-graph-up-arrow" />
            </a>
            <a
              className="link"
              href={`admin?uuid=${villageId}`}
              onclick="notifAlert()"
            >
              Admin Login <i className="bi bi-geo" />
            </a>
          </Box>
        </Box>
      </Box>
      <Box className="title-big">
        <Typography variant='h1'> Welcome In {data?.village_name}</Typography>
        <span>{data?.message}</span>
        <Box className="menu-link">
          <a
            className="a-link"
            href={`Register-user?uuid=${villageId}`}
          >
            
            Register User
          </a>
          <a className="a-link" href={`User-list?uuid=${villageId}`} onclick="notifAlert()">
            User List
          </a>
          <a className="a-link" href={`admin?uuid=${villageId}`} onclick="notifAlert()">
            Admin Login
          </a>
          <a
            className="a-link"
            href="https://neutronautomation.in/contact"
            target="_self"
          >
           Contact Us
          </a>
        </Box>
        <Box className="loc">
          <i className="bi bi-geo-alt" /> {data?.welcome_address}
        </Box>
      </Box>
      <Box id="swd-slideshow">
        <Box
          id="img5s"
          style={{
            backgroundImage:
              'url("https://cdn.wallpapersafari.com/42/9/INQdnT.jpg")'
          }}
        />
        <Box
          id="img2"
          style={{
            backgroundImage:
              'url("https://cdn.wallpapersafari.com/37/42/Ro2NKk.jpg")'
          }}
        />
        <Box
          id="img3"
          style={{
            backgroundImage:
              'url("https://cdn.wallpapersafari.com/19/94/3BbCuU.jpg")'
          }}
        />
        <Box
          id="img4"
          style={{
            backgroundImage:
              'url("https://cdn.wallpapersafari.com/19/94/3BbCuU.jpg")'
          }}
        />
        <Box
          id="img1"
          style={{
            backgroundImage:
              'url("https://wallpapercave.com/wp/wp6366375.jpg")'
          }}
        />
      </Box>
    </>
  );
}

export default App;
