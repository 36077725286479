import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Box, Button, FormHelperText } from '@mui/material';
import './style.css';
import ReactPlayer from 'react-player';
import promoVideo from './assets/demo.mp4';

function VideoPlay() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const [loginMessage, setLoginMessage] = useState('');
    const uuid = localStorage.getItem('villageId')
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phoneNumber: '',
        address: '',
        gender: '',
        latitude: null,
        longitude: null
    });
    const [formDataArray, setFormDataArray] = useState([]);

    setTimeout(() => {
        setOpen(false);
    }, 15000);

    useEffect(() => {
        const savedData = localStorage.getItem('personalInfoArray');
        if (savedData) {
            setFormDataArray(JSON.parse(savedData));
        }
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!formData.latitude) {
            setLoginMessage('Location is Required')
            return false
        }
        // Check for existing user
        const isExistingUser = formDataArray.some(
            (entry) => entry.email === formData.email || entry.phoneNumber === formData.phoneNumber
        );

        if (isExistingUser) {
            alert('User is already registered.');
            return;
        }

        const newEntry = {
            id: formDataArray.length + 1,
            ...formData
        };

        const updatedArray = [...formDataArray, newEntry];

        localStorage.setItem('personalInfoArray', JSON.stringify(updatedArray));

        setFormDataArray(updatedArray);
        alert('Form Data Saved:');

        setFormData({
            fullName: '',
            email: '',
            phoneNumber: '',
            address: '',
            gender: '',
            latitude: null,
            longitude: null
        });

        navigate(`/?uuid=${uuid}`);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '750px',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        outline: 'none',
    };

    const videoStyle = {
        width: '100%',
        height: '100%',
    };
    setTimeout(() => {
        setLoginMessage('');
    }, 3000);
    const handleGetLocation = async () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    }));
                },
            );
            alert('Location Get Successfully');
        }
    }

    const handleBack = () => {
        navigate(`/?uuid=${uuid}`);
    };

    return (
        <Box style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button variant="contained" onClick={handleBack} sx={{ position: 'absolute', top: 16, left: 16 }}>
                Back
            </Button>

            {!open &&
                <Box className="container">
                    <Box className="title">Personal Information</Box>
                    <form onSubmit={handleSubmit}>
                        <Box className="user__details">
                            <Box className="input__box">
                                <span className="details">Full Name</span>
                                <input type="text" placeholder="Sanket Patil" required name="fullName" value={formData.fullName} onChange={handleInputChange} />
                            </Box>
                            <Box className="input__box">
                                <span className="details">Email</span>
                                <input type="email" placeholder="name@example.com" required name="email" value={formData.email} onChange={handleInputChange} />
                            </Box>
                            <Box className="input__box">
                                <span className="details">Phone Number</span>
                                <input type="tel" pattern="[0-9]{3}[0-9]{3}[0-9]{4}" placeholder="1234567890" required name="phoneNumber" value={formData.phoneNumber} onChange={handleInputChange} />
                            </Box>
                            <Box className="input__box">
                                <span className="details">Address</span>
                                <input type="text" placeholder="Pune, Maharastra. 422-605" required name="address" value={formData.address} onChange={handleInputChange} />
                            </Box>
                        </Box>

                        <Box className="button" style={{ marginLeft: '6%', cursor: 'pointer' }}>
                            <input type="button" onClick={handleGetLocation} required value='Get Location' />
                            <input type="submit" value="Submit" style={{ marginLeft: 30 }} />
                            {loginMessage && (
                            <FormHelperText error>
                                {loginMessage}
                            </FormHelperText>
                        )}
                        </Box>
                        {/* <Box className="button" style={{ marginLeft: '40%', cursor: 'pointer' }}>
                            <input type="submit" value="Submit" />
                        </Box> */}
                    </form>
                </Box>
            }
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
            >
                <Box sx={style}>
                    <ReactPlayer style={videoStyle} url={promoVideo} playing />
                </Box>
            </Modal>
        </Box>
    );
}

export default VideoPlay;
